import React from 'react';
import {WhyChooseUsSection,WhyChooseUsLeftLayout,WhyChooseUsHeading,WhyChooseUsSingle,
    NumberLayout,ImgIcon,TextLayout,SubHeading,Content,WhyChooseUsRightLayout
} from './pooltypes.style';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const WhyChooseUsPage  = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        services1Json{
            PoolTypes{
                WhyChooseUsHeading
                Details{
                        NumberLayout
                        SubHeading
                        Content
                    }
                RightImage{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
  `);

    return (
            <WhyChooseUsSection>
                <WhyChooseUsRightLayout>
                    <GatsImg 
                        fluid={JSONData.services1Json.PoolTypes.RightImage.childImageSharp.fluid} 
                        alt=""
                        className="RightImage"
                    />
                </WhyChooseUsRightLayout>
                <WhyChooseUsLeftLayout>
                    <WhyChooseUsHeading>
                    {JSONData.services1Json.PoolTypes.WhyChooseUsHeading}
                    </WhyChooseUsHeading>
                    <WhyChooseUsSingle>
                        <NumberLayout>
                        <ImgIcon
                            src= {JSONData.services1Json.PoolTypes.Details[0].NumberLayout}
                            alt=""
                            />
                        </NumberLayout>
                        <TextLayout>
                            <SubHeading>
                            {JSONData.services1Json.PoolTypes.Details[0].SubHeading}
                            </SubHeading>
                            <Content marginBottom>
                            {JSONData.services1Json.PoolTypes.Details[0].Content}
                            </Content>
                        </TextLayout>
                    </WhyChooseUsSingle>
                    <WhyChooseUsSingle>
                        <NumberLayout BeforeReq>
                            <ImgIcon
                                src= {JSONData.services1Json.PoolTypes.Details[1].NumberLayout}
                                alt=""
                                />
                        </NumberLayout>
                        <TextLayout>
                            <SubHeading>
                            {JSONData.services1Json.PoolTypes.Details[1].SubHeading}
                            </SubHeading>
                            <Content marginBottom>
                            {JSONData.services1Json.PoolTypes.Details[1].Content}
                            </Content>
                        </TextLayout>
                    </WhyChooseUsSingle>
                    <WhyChooseUsSingle>
                        <NumberLayout>
                            <ImgIcon
                            src= {JSONData.services1Json.PoolTypes.Details[2].NumberLayout}
                            alt=""
                            />
                        </NumberLayout>
                        <TextLayout>
                            <SubHeading>
                            {JSONData.services1Json.PoolTypes.Details[2].SubHeading}
                            </SubHeading>
                            <Content>
                            {JSONData.services1Json.PoolTypes.Details[2].Content}
                            </Content>
                        </TextLayout>
                    </WhyChooseUsSingle>
                </WhyChooseUsLeftLayout>
                </WhyChooseUsSection>
    );
}
export default WhyChooseUsPage;