import React from 'react';
import {AboutSection,AboutInner,
    AboutLeft,AboutRight,AboutLeftHeading,AboutLeftPara,AboutRightImgHolder,
    AboutRow
} from './about.style';
import {Container, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const About  = () => {

    const JSONData  = useStaticQuery(graphql`
    query {
        services1Json{
            About{
                AboutRightImg{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                AboutLeftHeading
                AboutLeftPara1
                AboutLeftPara2
            }
        }
    }
  `);

    return (
        <AboutSection id="AboutContainer">
            <Container>
                <AboutInner>
                    <AboutRow>
                        <Col md="6">
                            <AboutRight>
                                <AboutRightImgHolder>
                                    <GatsImg 
                                        fluid={JSONData.services1Json.About.AboutRightImg.childImageSharp.fluid} 
                                        alt=""
                                    />
                                </AboutRightImgHolder>
                            </AboutRight>
                        </Col>

                        <Col md="6">
                            <AboutLeft>
                                <AboutLeftHeading>
                                {JSONData.services1Json.About.AboutLeftHeading}
                                </AboutLeftHeading>
                                <AboutLeftPara>
                                {JSONData.services1Json.About.AboutLeftPara1}
                                </AboutLeftPara>
                                <AboutLeftPara>
                                {JSONData.services1Json.About.AboutLeftPara2}
                                </AboutLeftPara>
                            </AboutLeft>
                        </Col>
                    </AboutRow>
                </AboutInner>
            </Container>
        </AboutSection>
    );
}
export default About;