import React from 'react';
import {WhyChooseUsSection,WhyChooseUsLeftLayout,WhyChooseUsHeading,WhyChooseUsSingle,
    Content,WhyChooseUsRightLayout
} from './imagetext2.style';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const ImageText2  = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        services1Json{
            ImageText2{
                WhyChooseUsHeading
                Content1
                Content2
                RightImage{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
  `);

    return (
            <WhyChooseUsSection>
                <WhyChooseUsRightLayout>
                    <GatsImg 
                        fluid={JSONData.services1Json.ImageText2.RightImage.childImageSharp.fluid} 
                        alt=""
                        className="RightImage"
                    />
                </WhyChooseUsRightLayout>
                <WhyChooseUsLeftLayout>
                    <WhyChooseUsHeading>
                    {JSONData.services1Json.ImageText2.WhyChooseUsHeading}
                    </WhyChooseUsHeading>
                    <WhyChooseUsSingle>
                        <Content marginBottom>
                        {JSONData.services1Json.ImageText2.Content1}
                        </Content>
                        <Content>
                        {JSONData.services1Json.ImageText2.Content2}
                        </Content>
                    </WhyChooseUsSingle>
                </WhyChooseUsLeftLayout>
            </WhyChooseUsSection>
    );
}
export default ImageText2;