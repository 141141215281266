import React from 'react';
import {ServicesWeProvideSection,ServicesLayout,HeadingLayout,ServicesWeProvideHeading,
    ServicesSingleLayout,ServiceImg,ServiceHeading,ServiceText,RightImageHolder
} from './servicesweprovide.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const ServiceWeProvide  = () => {

    const JSONData  = useStaticQuery(graphql`
    query {
        services1Json{
            ServicesWeProvide{
                ServicesWeProvideHeading
                ServiceImg1
                ServiceHeading1
                ServiceText1
                ServiceImg2
                ServiceHeading2
                ServiceText2
                ServiceImg3
                ServiceHeading3
                ServiceText3
                ServiceImg4
                ServiceHeading4
                ServiceText4
                RightImage{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
  `);

    return (
       <ServicesWeProvideSection>
           <Container>
                <Row>
                    <Col lg={8}>
                        <ServicesLayout>
                            <HeadingLayout>
                                <ServicesWeProvideHeading>
                                    {JSONData.services1Json.ServicesWeProvide.ServicesWeProvideHeading}
                                </ServicesWeProvideHeading>
                            </HeadingLayout>
                            <Row>
                                <Col md={6} sm={6}>
                                    <ServicesSingleLayout>
                                        <ServiceImg 
                                                src={JSONData.services1Json.ServicesWeProvide.ServiceImg1} 
                                                alt=""
                                            />
                                        <ServiceHeading>
                                        {JSONData.services1Json.ServicesWeProvide.ServiceHeading1}
                                        </ServiceHeading>
                                        <ServiceText>
                                        {JSONData.services1Json.ServicesWeProvide.ServiceText1}
                                        </ServiceText>
                                    </ServicesSingleLayout>
                                </Col>
                                <Col md={6} sm={6}>
                                    <ServicesSingleLayout>
                                        <ServiceImg 
                                                src={JSONData.services1Json.ServicesWeProvide.ServiceImg2} 
                                                alt=""
                                            />
                                        <ServiceHeading>
                                        {JSONData.services1Json.ServicesWeProvide.ServiceHeading2}
                                        </ServiceHeading>
                                        <ServiceText>
                                        {JSONData.services1Json.ServicesWeProvide.ServiceText2}
                                        </ServiceText>
                                    </ServicesSingleLayout>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} sm={6}>
                                    <ServicesSingleLayout>
                                        <ServiceImg 
                                                src={JSONData.services1Json.ServicesWeProvide.ServiceImg3} 
                                                alt=""
                                            />
                                        <ServiceHeading>
                                        {JSONData.services1Json.ServicesWeProvide.ServiceHeading3}
                                        </ServiceHeading>
                                        <ServiceText>
                                        {JSONData.services1Json.ServicesWeProvide.ServiceText3}
                                        </ServiceText>
                                    </ServicesSingleLayout>
                                </Col>
                                <Col md={6} sm={6}>
                                    <ServicesSingleLayout>
                                        <ServiceImg 
                                                src={JSONData.services1Json.ServicesWeProvide.ServiceImg4} 
                                                alt=""
                                            />
                                        <ServiceHeading>
                                        {JSONData.services1Json.ServicesWeProvide.ServiceHeading4}
                                        </ServiceHeading>
                                        <ServiceText>
                                        {JSONData.services1Json.ServicesWeProvide.ServiceText4}
                                        </ServiceText>
                                    </ServicesSingleLayout>
                                </Col>
                            </Row>
                        </ServicesLayout>
                    </Col>
                    <Col lg={4}>
                        <RightImageHolder>
                            <GatsImg 
                                fluid={JSONData.services1Json.ServicesWeProvide.RightImage.childImageSharp.fluid} 
                                alt=""
                                className="RightImage"
                            />
                        </RightImageHolder>
                    </Col>
                </Row>
           </Container>
       </ServicesWeProvideSection>
    );
}
export default ServiceWeProvide;