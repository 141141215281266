import React,{Component} from 'react';
import {ServicesGallerySection,GalleryWrapper,GalleryHeading,ImageHolder1,ImageHolder2,
    NextPrev,PrevImg,NextImg
} from './servicesgallery.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class ServicesGallery extends Component {
    constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }
    openDialogBox(imageArray,idx)
    {
        this.props.openLightBox(imageArray,idx);
    }
    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }
    
    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      initialSlide: 1
                    }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                }
            ]
        };
        return (
            <ServicesGallerySection>
                <Container>
                    <Slider ref={c => (this.slider = c)} {...settings}>
                        <GalleryWrapper>
                            <GalleryHeading>
                            {this.props.services1Json.ServicesGallery.GalleryHeading} 
                            </GalleryHeading>
                            <Row>
                                <Col md={8}>

                                    <Row>
                                        <Col md={6}>
                                            <ImageHolder1 onClick={this.openDialogBox.bind(this,this.props.services1Json.ServicesGallery,0)}>
                                                <GatsImg 
                                                    fluid={this.props.services1Json.ServicesGallery.GalleryImage1.childImageSharp.fluid} 
                                                    alt=""
                                                    className="GalleryImg1"
                                                />
                                            </ImageHolder1>
                                        </Col>
                                        <Col md={6}>
                                            <ImageHolder1 onClick={this.openDialogBox.bind(this,this.props.services1Json.ServicesGallery,1)}>
                                                <GatsImg 
                                                    fluid={this.props.services1Json.ServicesGallery.GalleryImage2.childImageSharp.fluid} 
                                                    alt=""
                                                    className="GalleryImg1"
                                                />
                                            </ImageHolder1>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12}>
                                            <ImageHolder1 onClick={this.openDialogBox.bind(this,this.props.services1Json.ServicesGallery,2)}>
                                                <GatsImg 
                                                    fluid={this.props.services1Json.ServicesGallery.GalleryImage3.childImageSharp.fluid} 
                                                    alt=""
                                                    className="GalleryImg1"
                                                />
                                            </ImageHolder1>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={4}>
                                    <ImageHolder2 onClick={this.openDialogBox.bind(this,this.props.services1Json.ServicesGallery,3)}>
                                        <GatsImg 
                                            fluid={this.props.services1Json.ServicesGallery.GalleryImage4.childImageSharp.fluid} 
                                            alt=""
                                            className="GalleryImg2"
                                        />
                                    </ImageHolder2>
                                </Col>
                            </Row>
                        </GalleryWrapper>
                        <GalleryWrapper>
                            <GalleryHeading>
                            {this.props.services1Json.ServicesGallery.GalleryHeading} 
                            </GalleryHeading>
                            <Row>
                                <Col md={8}>

                                    <Row>
                                        <Col md={6}>
                                            <ImageHolder1 onClick={this.openDialogBox.bind(this,this.props.services1Json.ServicesGallery,4)}>
                                                <GatsImg 
                                                    fluid={this.props.services1Json.ServicesGallery.GalleryImage5.childImageSharp.fluid} 
                                                    alt=""
                                                    className="GalleryImg1"
                                                />
                                            </ImageHolder1>
                                        </Col>
                                        <Col md={6}>
                                            <ImageHolder1 onClick={this.openDialogBox.bind(this,this.props.services1Json.ServicesGallery,5)}>
                                                <GatsImg 
                                                    fluid={this.props.services1Json.ServicesGallery.GalleryImage6.childImageSharp.fluid} 
                                                    alt=""
                                                    className="GalleryImg1"
                                                />
                                            </ImageHolder1>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12}>
                                            <ImageHolder1 onClick={this.openDialogBox.bind(this,this.props.services1Json.ServicesGallery,6)}>
                                                <GatsImg 
                                                    fluid={this.props.services1Json.ServicesGallery.GalleryImage7.childImageSharp.fluid} 
                                                    alt=""
                                                    className="GalleryImg1"
                                                />
                                            </ImageHolder1>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={4}>
                                    <ImageHolder2 onClick={this.openDialogBox.bind(this,this.props.services1Json.ServicesGallery,7)}>
                                        <GatsImg 
                                            fluid={this.props.services1Json.ServicesGallery.GalleryImage8.childImageSharp.fluid} 
                                            alt=""
                                            className="GalleryImg2"
                                        />
                                    </ImageHolder2>
                                </Col>
                            </Row>
                        </GalleryWrapper>
                    </Slider>
                    <NextPrev>
                        <button aria-label="Prev Button" onClick={this.previous}>
                            <PrevImg src={this.props.services1Json.ServicesGallery.PrevImg} alt=""/>
                        </button>
                        <button aria-label="Next Button" onClick={this.next}>
                            <NextImg src={this.props.services1Json.ServicesGallery.NextImg} alt=""/>
                        </button>
                    </NextPrev>
                </Container>
            </ServicesGallerySection>
    );
}
}

export default props => (
<StaticQuery
    query={graphql`
        query {
            services1Json{
                ServicesGallery{
                    GalleryHeading
                    PrevImg
                    NextImg
                    GalleryImage1{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    GalleryImage2{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    GalleryImage3{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    GalleryImage4{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    GalleryImage5{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    GalleryImage6{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    GalleryImage7{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    GalleryImage8{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `}
    render={(data) => (
        <ServicesGallery 
        services1Json={data.services1Json}
        {...props}
        />
    )}
/>
)